var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{class:{
      [_vm.$style.card__register]: true,
    }},[_c('div',{class:{
        [_vm.$style.card__register__image]: true,
      }},[_c('img',{attrs:{"src":"https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482","width":"170","height":"82.13"}})]),_c('div',{staticClass:"pbz-font subtitle-md-medium mt-5 text-left",staticStyle:{"color":"#1a1a1a"}},[_vm._v(" "+_vm._s(_vm.$t('auth.register'))+" ")]),_vm._l((_vm.formError),function(item,index){return _c('div',{key:index},_vm._l((item.error_message),function(error,indexError){return _c('div',{key:indexError,staticClass:"pbz-font body-sm-regular text-left mt-2",staticStyle:{"color":"#ee4426"}},[_vm._v(" "+_vm._s(error)+" "),_c('br')])}),0)}),_c('div',{staticStyle:{"margin-top":"63px"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('user_login'),
          },attrs:{"prop":"user_login","rules":[{
            required: true,
            validator: _vm.emailPhoneValidator,
          }]}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.user_login),callback:function ($$v) {_vm.$set(_vm.form, "user_login", $$v)},expression:"form.user_login"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.emailPhoneLabel')))])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('password'),
          },attrs:{"prop":"password","rules":[{
            required: true,
            validator: _vm.passwordValidator,
          }]}},[_c('a-input-password',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.password'))+" ")])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('confirm_password'),
          },attrs:{"prop":"confirm_password","rules":[{
            required: true,
            validator: _vm.confirmPasswordValidator,
          }]}},[_c('a-input-password',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('auth.confirmPassword'))+" ")])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
            ['mb-3']: _vm.checkFormHaveError('name'),
          },attrs:{"prop":"name","rules":[{
            required: true,
            validator: _vm.nameValidator,
          }]}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.name')))])])],1),_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
          },attrs:{"prop":"business_name"}},[_c('a-input',{class:{
              [_vm.$style.container__form__input]: true,
            },model:{value:(_vm.form.business_name),callback:function ($$v) {_vm.$set(_vm.form, "business_name", $$v)},expression:"form.business_name"}}),_c('label',{staticClass:"pbz-font overline-normal",class:{
              [_vm.$style.container__form__label]: true,
            }},[_c('span',[_vm._v(_vm._s(_vm.$t('auth.businessName')))])])],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
            [_vm.$style.button__submit]: true,
          },attrs:{"size":"large","html-type":"submit"}},[(!_vm.loading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('auth.register'))+" ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()])],1)],1),_c('div',{staticClass:"pbz-font button-sm-medium my-5 d-flex justify-content-center"},[_c('span',{staticClass:"mx-2",staticStyle:{"color":"#999999 !important"}},[_vm._v(" "+_vm._s(_vm.$t('auth.hasAccount'))+" ")]),_c('span',{staticStyle:{"color":"#0559CB !important","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return (() => _vm.$router.push({ path: '/auth/login' })).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('auth.loginHere'))+" ")])]),_c('div',{staticClass:"mt-3 text-center"},[_c('div',{staticClass:"anotherSign pbz-font body-sm-medium",staticStyle:{"color":"#666666 !important"}},[_vm._v(" "+_vm._s(_vm.$t('auth.anotherRegister'))+" ")])]),_c('div',{staticClass:"mt-5 mb-2",class:{
        [_vm.$style.anotherRegisterCard]: true,
      }},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/PowerBiz/google-logo.png")}}),_c('div',{staticClass:"mx-3 pbz-font body-md-medium"},[_vm._v(" "+_vm._s(_vm.$t('auth.signUpWithGoogle'))+" ")])]),_c('div',{staticClass:"mt-3 mb-2",class:{
        [_vm.$style.anotherRegisterCard]: true,
      }},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/PowerBiz/facebook-logo.png")}}),_c('div',{staticClass:"mx-3 pbz-font body-md-medium"},[_vm._v(" "+_vm._s(_vm.$t('auth.signUpWithFacebook'))+" ")])]),_c('div',{staticClass:"pt-5 mt-5 text-center"},[_c('Footer')],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }