<template>
  <div class="row">
    <div
      :class="{
        [$style.card__register]: true,
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482"
          width="170"
          height="82.13"
        />
      </div>

      <div class="pbz-font subtitle-md-medium mt-5 text-left" style="color: #1a1a1a;">
        {{ $t('auth.register') }}
      </div>

      <div v-for="(item, index) in formError" :key="index">
        <div
          v-for="(error, indexError) in item.error_message"
          :key="indexError"
          class="pbz-font body-sm-regular text-left mt-2"
          style="color: #ee4426;"
        >
          {{ error }} <br />
        </div>
      </div>

      <div class="" style="margin-top: 63px;">
        <a-form-model ref="ruleForm" :model="form" @submit.prevent="handleSubmit">
          <a-form-model-item
            prop="user_login"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('user_login'),
            }"
            :rules="[{
              required: true,
              validator: emailPhoneValidator,
            }]"
          >
            <a-input
              v-model="form.user_login"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span>{{ $t('auth.emailPhoneLabel') }}</span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="password"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('password'),
            }"
            :rules="[{
              required: true,
              validator: passwordValidator,
            }]"
          >
            <a-input-password
              v-model="form.password"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span> {{ $t('auth.password') }} </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="confirm_password"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('confirm_password'),
            }"
            :rules="[{
              required: true,
              validator: confirmPasswordValidator,
            }]"
          >
            <a-input-password
              v-model="form.confirm_password"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span> {{ $t('auth.confirmPassword') }} </span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="name"
            :class="{
              [$style.container__form]: true,
              ['mb-3']: checkFormHaveError('name'),
            }"
            :rules="[{
              required: true,
              validator: nameValidator,
            }]"
          >
            <a-input
              v-model="form.name"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span>{{ $t('auth.name') }}</span>
            </label>
          </a-form-model-item>

          <a-form-model-item
            prop="business_name"
            :class="{
              [$style.container__form]: true,
            }"
          >
            <a-input
              v-model="form.business_name"
              :class="{
                [$style.container__form__input]: true,
              }"
            />

            <label
              :class="{
                [$style.container__form__label]: true,
              }"
              class="pbz-font overline-normal"
            >
              <span>{{ $t('auth.businessName') }}</span>
            </label>
          </a-form-model-item>

          <a-button
            size="large"
            class="pbz-font body-md-bold text-center w-100 mt-3"
            :class="{
              [$style.button__submit]: true,
            }"
            html-type="submit"
          >
            <div v-if="!loading">
              {{ $t('auth.register') }}
            </div>
            <div v-if="loading">
              <a-icon type="loading" /> Loading
            </div>
          </a-button>
        </a-form-model>
      </div>

      <div class="pbz-font button-sm-medium my-5 d-flex justify-content-center">
        <span style="color: #999999 !important;" class="mx-2">
          {{ $t('auth.hasAccount') }}
        </span>
        <span style="color: #0559CB !important; cursor: pointer" @click.prevent="() => $router.push({ path: '/auth/login' })"> {{ $t('auth.loginHere') }} </span>
      </div>

      <div class="mt-3 text-center">
        <div
          style="color: #666666 !important;"
          class="anotherSign pbz-font body-sm-medium"
        >
          {{ $t('auth.anotherRegister') }}
        </div>
      </div>

      <div
        :class="{
          [$style.anotherRegisterCard]: true,
        }"
        class="mt-5 mb-2"
      >
        <img class="mx-1" src="@/assets/PowerBiz/google-logo.png" />
        <div class="mx-3 pbz-font body-md-medium">
          {{ $t('auth.signUpWithGoogle') }}
        </div>
      </div>

      <div
        :class="{
          [$style.anotherRegisterCard]: true,
        }"
        class="mt-3 mb-2"
      >
        <img class="mx-1" src="@/assets/PowerBiz/facebook-logo.png" />
        <div class="mx-3 pbz-font body-md-medium">
          {{ $t('auth.signUpWithFacebook') }}
        </div>
      </div>
      <div class="pt-5 mt-5 text-center">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Footer from '@/components/cleanui/layout/Footer'

export default {
  name: 'CuiRegister',
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Footer },
  data: function () {
    return {
      form: {
        user_login: '',
        password: '',
        confirm_password: '',
        name: '',
        business_name: '',
      },
      dataPhoneError: {
        field: null,
        error_message: [],
      },
      passwordError: {
        field: null,
        error_message: [],
      },
      confirmPasswordError: {
        field: null,
        error_message: [],
      },
      nameError: {
        field: null,
        error_message: [],
      },
      emailPhoneValidator: (rule, value, callback) => {
        const { field } = rule
        this.dataPhoneError.field = field

        const findErrorMessageEmailPhoneRequired = this.checkExistingError(
          this.dataPhoneError.error_message,
          this.$t('auth.emailAndPhoneBlank'),
        )

        const findErrorMessageInvalidInput = this.checkExistingError(
          this.dataPhoneError.error_message,
          this.$t('auth.invalidEmailPhoneNumber'),
        )

        if (!value) {
          !findErrorMessageEmailPhoneRequired &&
            this.dataPhoneError.error_message.push(
              this.$t('auth.emailAndPhoneBlank'),
            )
          return callback(this.$t('auth.emailAndPhoneBlank'))
        }

        // eslint-disable-next-line
        const pattern = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{1,100})$/g

        if (!pattern.test(value)) {
          !findErrorMessageInvalidInput &&
            this.dataPhoneError.error_message.push(
              this.$t('auth.invalidEmailPhoneNumber'),
            )
          return callback(this.$t('auth.invalidEmailPhoneNumber'))
        }

        this.dataPhoneError.error_message = []
        return callback()
      },
      passwordValidator: (rule, value, callback) => {
        const { field } = rule
        this.passwordError.field = field

        const findErrorLengthMessage = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.minimumLengthPassword'),
        )
        const findErrorMessageOneUppercas = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneUppercase'),
        )
        const findErrorMessageOneNumber = this.checkExistingError(
          this.passwordError.error_message,
          this.$t('auth.atLeastOneNumber'),
        )

        if (!value) {
          !findErrorLengthMessage &&
            this.passwordError.error_message.push(
              this.$t('auth.minimumLengthPassword'),
            )
          !findErrorMessageOneUppercas &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneUppercase'),
            )
          !findErrorMessageOneNumber &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneNumber'),
            )
          return callback(this.$t('auth.minimumLengthPassword'))
        }

        const patternLength = /^.{6,}$/
        // const findErrorLengthMessage = this.passwordError.error_message.find(item => item === this.$t('auth.minimumLengthPassword'))

        if (!patternLength.test(value)) {
          !findErrorLengthMessage &&
            this.passwordError.error_message.push(
              this.$t('auth.minimumLengthPassword'),
            )
          return callback(this.$t('auth.minimumLengthPassword'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.minimumLengthPassword')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        const patternOneUppercase = /(?=.*[A-Z])/
        // const findErrorMessageOneUppercas = this.passwordError.error_message.find(item => item === this.$t('auth.atLeastOneUppercase'))

        if (!patternOneUppercase.test(value)) {
          !findErrorMessageOneUppercas &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneUppercase'),
            )
          return callback(this.$t('auth.atLeastOneUppercase'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneUppercase')) {
              newMessage.push(item)
            }
          })
          this.passwordError.error_message = newMessage
        }

        const patternNumber = /(?=.*[0-9])/
        // const findErrorMessageOneNumber = this.passwordError.error_message.find(item => item === this.$t('auth.atLeastOneNumber'))

        if (!patternNumber.test(value)) {
          !findErrorMessageOneNumber &&
            this.passwordError.error_message.push(
              this.$t('auth.atLeastOneNumber'),
            )
          return callback(this.$t('auth.atLeastOneNumber'))
        } else {
          const newMessage = []
          this.passwordError.error_message.map((item) => {
            if (item !== this.$t('auth.atLeastOneNumber')) {
              newMessage.push(item)
            }
          })

          this.passwordError.error_message = newMessage
        }

        if (this.passwordError.error_message.length) {
          return callback('error')
        } else {
          return callback()
        }
      },
      confirmPasswordValidator: (rule, value, callback) => {
        const { field } = rule
        this.confirmPasswordError.field = field
        const password = this.form.password

        const findErrorMessagePasswordNotMatch = this.checkExistingError(
          this.confirmPasswordError.error_message,
          this.$t('auth.passwordNotMatch'),
        )
        if (value !== password || !value) {
          !findErrorMessagePasswordNotMatch &&
            this.confirmPasswordError.error_message.push(
              this.$t('auth.passwordNotMatch'),
            )
          return callback(this.$t('auth.passwordNotMatch'))
        } else {
          this.confirmPasswordError.error_message = []
          return callback()
        }
      },
      nameValidator: (rule, value, callback) => {
        const { field } = rule
        this.nameError.field = field

        const findErrorMessageRequiredName = this.checkExistingError(
          this.nameError.error_message,
          this.$t('auth.requiredName'),
        )
        if (!value) {
          !findErrorMessageRequiredName &&
            this.nameError.error_message.push(this.$t('auth.requiredName'))
          return callback(this.$t('auth.requiredName'))
        } else {
          this.nameError.error_message = []
          return callback()
        }
      },
    }
  },
  computed: {
    ...mapState(['settings']),
    loading() {
      return this.$store.state.user.loading
    },
    formError() {
      if (
        !this.dataPhoneError.error_message.length &&
        !this.passwordError.error_message?.length &&
        !this.confirmPasswordError.error_message?.length &&
        !this.nameError.error_message?.length
      ) {
        return []
      }

      const data = []
      !!this.dataPhoneError.error_message?.length &&
        data.push(this.dataPhoneError)
      !!this.passwordError.error_message?.length &&
        data.push(this.passwordError)
      !!this.confirmPasswordError.error_message?.length &&
        data.push(this.confirmPasswordError)
      !!this.nameError.error_message?.length && data.push(this.nameError)

      return data
    },
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return

        const typeRegister = this.form.user_login.indexOf('@') > -1 ? 'email' : 'phone'

        this.$store.dispatch('user/SIGNUP', {
          ...this.form,
          typeRegister,
        })
          .then(() => {
            if (typeRegister === 'phone') {
              this.$router.push({ path: '/auth/reg-conf/phone-number' })
            } else if (typeRegister === 'email') {
              this.$router.push({ path: '/auth/reg-conf/email' })
            }
          })
          .catch((err) => {
            this.$notification.error({
              message: err.response.data.errors ? err.response.data.errors[0].message : err.response.data.message ? err.response.data.message : 'Internal Server Error',
            })
          })
      })
    },
    checkFormHaveError(paramater) {
      return this.formError.find((item) => item.field === paramater)
    },
    checkExistingError(errorLog = [], stringParam = '') {
      return errorLog.find((item) => item === stringParam)
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>

<style lang="scss">
.ant-form-explain {
  display: none !important;
}

.anotherSign:before,
.anotherSign:after {
  background-color: #666666;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 20%;
}

.optionSign:before {
  right: 0.5em;
  margin-left: -50%;
}

.optionSign:after {
  left: 0.5em;
  margin-right: -50%;
}
@media only screen and (max-width: 376px) {
  .row {
    width: 80%;
    margin-left: 50px;
  }
}
</style>
