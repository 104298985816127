<template>
  <div class="text-center mx-auto">
    <cui-register class="mb-3 mt-3 pt-3" />
  </div>
</template>
<script>
import CuiRegister from '@/components/cleanui/system/Auth/Register'
export default {
  components: {
    CuiRegister,
  },
}
</script>
